.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.padding-bottom-unset {
  padding-bottom: unset !important;
}

.margin-top-2rem {
  margin-top: 2rem !important;
}

.ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-with-remove {
  margin-bottom: unset;
}

.treatment-tab .ant-tabs-nav-add {
  margin-top: 2px;
  height: 2rem;
  border-radius: 0.625rem !important;
}

.treatment-tab .ant-input.ant-input-sm.ant-input-disabled {
  cursor: unset;
}

.treatment-tab .ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
  background: #3e79f7;
}

.treatment-tab .ant-tabs-tab-active .ant-tabs-tab-remove {
  color: white;
}

.treatment-tab .ant-tabs-tabpane.ant-tabs-tabpane-active span {
  display: flex;
}

.treatment-tab .ant-upload.ant-upload-btn {
  justify-content: center;
}

.treatment-tab .anticon.anticon-inbox {
  justify-content: center;
}

.patient-view .ant-upload-select-text {
  width: 100%;
}

.justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-right {
  display: flex;
  justify-content: right;
}

/* .list-item-fixed-height::-webkit-scrollbar {
  display: none;
}

.list-item-fixed-height {
  max-height: 600px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none
} */

.scrollbox {
  max-height: 600px;
  overflow-y: scroll;
}
.scrollbox::-webkit-scrollbar {
  width: 0px;
}
.scrollbox:hover::-webkit-scrollbar {
  width: 8px;
}
.scrollbox:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
@media (hover: none) {
  .scrollbox::-webkit-scrollbar {
    width: 8px;
  }
  .scrollbox::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.max-height-350 {
  max-height: 350px;
}

.max-height-400 {
  max-height: 400px;
}

.over-flow-x-hidden {
  overflow-x: hidden;
}

.card-medicine-items .ant-card-head {
  margin-top: -20px;
  margin-bottom: -20px;
}

.margin-bottom-0px {
  margin-bottom: 0px !important;
}

.color-black {
  color: black;
}

.item-prescription-history .ant-drawer-content-wrapper {
  width: 100% !important;
}

.list-prescription-history .ant-list-items {
  min-height: 400px;
}

.list-prescription-history .ant-modal-body {
  overflow-y: auto;
}

.overlay-loading {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0px;
  top: 0;
  position: absolute;
  right: 0;
  left: 0;
}

.demo-editor.rdw-editor-main {
  border: 1px solid rgb(230, 235, 241);
}

.preview-news-detail {
  overflow-y: auto;
  max-height: calc(100vh - 330px);
  border: 2px solid rgb(230, 235, 241);
}

.login-languages__wrapper:hover {
  background-color: #226b32 !important;

}

.login-languages__wrapper {
  background-color: #2e9143;
  border-radius: 0.625rem;
  position: relative;
}

.login-languages__wrapper a {
  color: white !important;
}

.login-languages__text {

}